body{
    background-color: hsl(214, 47%, 23%);
    color: white;
    font-family: 'Barlow Semi Condensed', sans-serif;
}

.proLogo-container{
    width: 40%;
    right: 30%;
    position: relative;
    left: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-top: 1%;
    border-radius: 10px;
    border: 2px solid ghostwhite;
    margin-bottom: 5%;
    padding-left:4px;
    padding-right:4px;
}

.score{
    font-size: 55px;
    margin: 20px 0px 20px 0px;
}
.result-row-1{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 45px;
}
.result-row-2{
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}
.row-1{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}
.row-2,.row-3{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.action-main{
    width: 40%;
    left: 30%;
    right: 30%;
    position: relative;
}
.actions-container{
    /* display:grid; */
    justify-content: center;
    height: 100px;
}

.actions-container button{
    border-radius: 100%;
    padding:10px;
}

.result-row-1 div:nth-child(1) button{
    border: 20px solid green;
}

.result-row-1 div:nth-child(3) button{
    border: 20px solid red;
 }
 /*
.result-big img{
    width: 68%;
    height: auto;
}
.result-big{
    width: 200%;
    height: 200%;   
}*/
.result-span{
    font-size: 30px;    
}
.row-1 button{
    border: 10px solid green;
}

.row-2 .actions-container:nth-child(1) button{
    border: 10px solid red;
}

.row-2 .actions-container:nth-child(2) button{
    border: 10px solid yellow;
}
.row-3 .actions-container:nth-child(1) button{
    border: 10px solid blueviolet;
}

.row-3 .actions-container:nth-child(2) button{
    border: 10px solid turquoise;
} 
.rules-container{
    margin: 25px 0 0 0;
    display:flex;
    justify-content: center;
}

#rules-button,#play-again{
    display: inline-block;
    color: white;
    border: 2px solid white;
    border-radius: 10px;
    /* justify-content: center; */
    margin:10px;
    width: 100px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 20px;
    background-color: hsl(214, 47%, 23%);
}
#result{
    font-size: 30px;
}
.result-div{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.rules-close{
    height: 20px;
    position: relative;
    background-color: hsl(214, 47%, 23%);
    left: 20px;
    color: white;
    border: none;
    font-family: 'Barlow Semi Condensed', sans-serif;
}
.navbar{
    position: relative;
    transition: top 1s cubic-bezier(0.05, 1.56, 0.87, 1.72);
    top:-36px;
    cursor: pointer;
}
.navbar div ul{
    padding-left:0px;
    display: flex;
}
.navbar div ul li{
    list-style-type: none;
    padding-right:10px;
}
div.icon{
    display: inline;
    float: right;
    position: relative;
    z-index: 1;
}

.dropdown{
    top:0px !important;
}
.dropdown img{
    height:20px;
}
.sideNav{
    position: fixed;
    height: 100%;
    width:0px;
    top: 10%;
    z-index: 1;
    transition: 0.5s;
}

.sideNavInFrame{
    width:18%!important;
}

.sideNavInFrame div{
    transform: translateX(0%);
    transition: transform 0.3s cubic-bezier(0.05, 1.56, 0.87, 1.72);
}

@media screen and (max-width:600px) {
    .proLogo-container{
        position:static;
        width:auto;
    }
    .result-row-1 div:nth-child(1) button{
        border: 10px solid green;
        margin-right:10px;
    }
    
    .result-row-1 div:nth-child(3) button{
        border: 10px solid red;
        margin-left:10px;
    }
    .result-row-1 div:nth-child(3) span{
        white-space: nowrap;
    }
    .rules-close{
        position: static;
    }
}
